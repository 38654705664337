html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
/* Scrollbars */
* {
  scrollbar-color: #d5d5d5 transparent;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
  width: 10px;
}

*::-webkit-scrollbar-button:increment,
*::-webkit-scrollbar-button {
  display: none;
}

@media (max-width: 768px) {
  *::-webkit-scrollbar:horizontal {
    display: none;
  }
}

*::-webkit-scrollbar-thumb {
  cursor: move;
  background-color: #d5d5d5;
  border-radius: 20px;
  border: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
